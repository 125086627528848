
import { mapState } from 'vuex'

export default {
  name: 'PageAffiliate',

  async fetch () { },

  computed: {
    ...mapState({
      user: state => state.auth.user
    }),
    affiliateCode () {
      return this.user.affiliateCode
    },
    referralLink () {
      return `https://yayforms.com/?afmc=${this.user.affiliateCode}`
    },
    showEmptyState () {
      return !this.$fetchState.pending && !this.$fetchState.error // && this.integrations.length === 0
    },
    showErrorState () {
      return this.$fetchState.error
    }
  },

  methods: {
    accessAffiliatePortal () {
      const email = this.user.email
      const url = `https://affiliate.yayforms.com/?email=${encodeURIComponent(email)}`
      window.open(url, '_blank')
    },
    async copy (value) {
      try {
        await navigator.clipboard.writeText(value)
        this.$notification.success(this, this.$t('components.account.ModalAffiliate.notification.success'))
      } catch {
        this.$notification.error(this, this.$t('components.account.ModalAffiliate.notification.error'))
      }
    }
  }
}
