export default {
  common: {
    field: {
      email: 'Correo electrónico',
      emailPlaceholder: 'Introduce tu correo electrónico...',
      password: 'Contraseña',
      passwordPlaceholder: 'Introduce tu contraseña...',
      passwordHelp: 'Se requiere al menos 8 caracteres, 1 número, letra en MAYÚSCULAS y caracteres especiales (!@#$%...).',
      fullName: 'Nombre completo',
      fullNamePlaceholder: 'Introduce tu nombre...',
      code: 'Código',
      previewOnly: 'Vista previa solamente 👀',
      typeYourAnswer: 'Escribe tu respuesta aquí...',
      press: 'presionar ',
      sayHiHere: 'Say hi here!',
      phone: 'Teléfono',
      phonePlaceholder: 'Introduce tu teléfono...',
      searchPlaceholder: 'Buscar país...',
      apiToken: 'Token de la API'
    },
    action: {
      signin: 'Iniciar sesión',
      signup: 'Registrarse',
      confirmCode: 'Confirmar código',
      signinWith: 'Iniciar sesión con',
      signupWith: 'Registrarse con',
      close: 'Cerrar',
      save: 'Guardar',
      cancel: 'Cancelar',
      delete: 'Eliminar',
      edit: 'Editar',
      add: 'Añadir',
      remove: 'Quitar',
      copy: 'Copiar',
      copyToClipboard: 'Copiar al portapapeles',
      yes: 'Sí',
      no: 'No',
      ok: 'Ok',
      next: 'Siguiente',
      back: 'Atrás',
      done: 'Hecho',
      continue: 'Continuar'
    },
    instruction: {
      pleaseWait: 'Por favor, espera...',
      importing: 'Importador...',
      loading: 'Cargando...',
      redirectFailed: 'Oops, no podemos redirigir en este momento. Por favor, inténtalo nuevamente en unos momentos.',
      permission: 'Parece que no nos has otorgado los permisos adecuados. Por favor, cierra esta ventana y comienza el proceso nuevamente asegurándote de seleccionar los permisos correctos.'
    },
    feedback: {
      copySuccess: '¡Copiado al portapapeles exitosamente!',
      copyError: 'Tu navegador no admite la función de copiar al portapapeles.'
    }
  },
  auth: {
    acceptInvitation: {
      accepting: 'Aceptando la invitación...',
      error: {
        wasNotPossibleAccept: 'Oops, no fue posible aceptar la invitación en este momento. Por favor, inténtalo de nuevo en unos minutos.',
        checkTheLink: 'Invitación no encontrada. Por favor, verifica el enlace e intenta nuevamente.',
        invitationExpired: 'La invitación ha expirado. Por favor, solicita una nueva.',
        invitationAlreadyAccepted: 'La invitación ya ha sido aceptada. Por favor, inicia sesión.'
      }
    },
    signin: {
      title: 'Me alegra verte 👋',
      signup: 'Registrarse',
      footer: '¿No tienes una cuenta de Yay! Forms?',
      forgotPassword: '¿Olvidaste tu contraseña?'
    },
    signup: {
      title: 'Crea tu cuenta 🫶',
      footer: '¿Ya tienes una cuenta de Yay! Forms?'
    },
    confirmation: {
      title: 'Confirma tu identidad 🪪',
      instructions: 'Puede que hayas recibido un código en tu correo electrónico (revisa también la carpeta de <b>spam</b>). Por favor, introduce el código a continuación para finalizar.',
      footer: '¿No recibiste el código?',
      sendAnotherCode: 'Enviar otro código',
      resendCodeFeedback: 'Te enviamos otro código. Por favor, espera unos segundos y revisa tu carpeta de spam.'
    },
    forgotPassword: {
      title: 'Recupera tu contraseña 🔑',
      sendResetInstructions: 'Enviar instrucciones de reseteo',
      sendResetFeedback: '¡Genial! Por favor, revisa las instrucciones de recuperación enviadas a tu correo electrónico.',
      footer: 'Ir a la página de <a href="/auth/signin" class="font-bold text-link">inicio de sesión</a>'
    },
    createPassword: {
      title: 'Crea tu contraseña 🔑',
      sendCreateInstructions: 'Enviar instrucciones de creación',
      sendCreateFeedback: '¡Genial! Por favor, revisa las instrucciones de creación de contraseña enviadas a tu correo electrónico.',
      footer: 'Ir a la página de <a href="/auth/signin" class="font-bold text-link">inicio de sesión</a>'
    },
    resetPassword: {
      title: 'Establece tu nueva contraseña',
      saveNewPassword: 'Guardar nueva contraseña',
      passwordUpdatedFeedback: '¡Contraseña actualizada con éxito!'
    }
  },
  billing: {
    cancel: {
      redirectingToDashBoard: 'Redireccionando al dashboard...'
    },
    checkout: {
      redirectingToCheckout: 'Redireccionando al proceso de pago...'
    },
    paymentMethods: {
      redirectingToPaymentMethods: 'Redireccionando a los métodos de pago...'
    },
    portal: {
      redirectingToBillingPortal: 'Redireccionando al portal de facturación...'
    },
    pricing: {
      title: 'Selecciona tu nuevo plan',
      yourPlan: 'Tu plan actual',
      per: 'por',
      pay: 'Pagar',
      monthly: 'Mensualmente',
      yearly: 'Anualmente',
      month: 'Mes',
      year: 'Año',
      freeTrial: 'Comenzar prueba gratuita',
      error: {
        pricingInformation: 'Ups, no pudimos cargar la información de precios en este momento. Por favor, inténtalo nuevamente en unos momentos.',
        affiliateInformation: 'Ups, no pudimos cargar la información de afiliados en este momento. Por favor, inténtalo nuevamente en unos momentos.'
      }
    },
    plans: {
      singleProfessional: '<b>20k</b> respuestas/mensuales',
      singlePlus: '<b>2k</b> respuestas/mensuales',
      singleStarter: '<b>200</b> respuestas/mensuales',
      singleFree: '<b>200</b> respuestas/mensuales',
      unlimited: 'Formularios ilimitados',
      customEndingScreens: 'Pantallas de finalización personalizadas',
      automaticKeyword: 'Extracción automática de palabras clave',
      sentimentDetection: 'Detección de sentimientos',
      emotionDetection: 'Detección de emociones'
    },
    success: {
      title: 'Tu suscripción se ha activado correctamente. Redireccionando al panel...'
    }
  },
  forms: {
    index: {
      edit: 'Por favor, abre este formulario en una pantalla más grande para poder editarlo.'
    },
    share: {
      isNotPublishedYet: 'Este formulario aún no está publicado. Será visible para los visitantes tan pronto como lo publiques.',
      tooltipPublic: 'Guardar cambios y hacer público este formulario.',
      publish: 'Publicar',
      share: 'Compartir tu formulario',
      link: 'This is the link to your form',
      tooltipCopy: 'Copiado al portapapeles.',
      copy: 'Copiar',
      tooltipQrCode: 'Generar código QR',
      embed: 'Insertar en tu sitio web',
      userTracking: 'Seguimiento de usuarios',
      embedCard: {
        wordpress: 'Descarga el plugin Yay! Forms para Wordpress.',
        standard: 'Mostrar tu formulario como una parte integral de tu página web.',
        fullPage: 'Mostrar un formulario a pantalla completa tan pronto como se cargue tu sitio.',
        popup: 'Tu formulario aparece en el centro de la pantalla.<br>Configura opciones personalizadas de inicio, como un retraso de tiempo.',
        slider: 'Tu formulario se deslizará desde el lateral (izquierdo o derecho).<br>Configura ajustes personalizados de inicio o ábrelo con un botón.',
        popover: 'Abrir como un popover flotante cuando alguien presiona el botón, o configurar parámetros de inicio personalizados.',
        sideTab: 'Abrir como un panel flotante cuando alguien presione el botón, o configurar parámetros de inicio personalizados.'
      },
      notification: {
        success: 'URL copiada al portapapeles.',
        error: 'Tu navegador no admite copiar al portapapeles.'
      }
    },
    integrate: {
      error: {
        loadIntegrations: 'Ups, no pudimos cargar las integraciones en este momento. Por favor, inténtalo nuevamente en unos momentos.',
        empty: 'Para gestionar tus integraciones, por favor ábrelas en una pantalla más grande.',
        loadWebhook: 'Ups, no pudimos cargar los webhooks en este momento. Por favor, inténtalo nuevamente en unos momentos.'
      },
      infoUrl: 'Proporciona una URL que recibirá los datos cada vez que alguien envíe un formulario.',
      addWebhook: 'Añadir webhook...',
      showPayload: 'Mostrar ejemplo de payload',
      payload: 'ejemplo de payload',
      exampleTitle: 'Es un ejemplo de cómo recibirás los datos que enviamos a tus webhooks.'
    },
    results: {
      inbox: {
        hasNoFields: 'Aquí puede obtener los resultados de sus respuestas tan pronto como cree campos y reciba respuestas.',
        noResponsesFound: 'No tienes ninguna respuesta.',
        deleteError: 'No fue posible borrar la respuesta',
        retrievingError: 'No ha sido posible recuperar las respuestas en este momento. Por favor, inténtelo de nuevo en unos minutos.',
        showOnlySubmitted: 'Mostrar sólo respuestas completas',
        download: 'Descargar',
        downloadResponse: 'Descargar respuesta seleccionada'
      },
      index: {
        switchContent: 'Mostrar/Ocultar detalles sobre las respuestas que cada pregunta recibió.',
        switchShow: 'Mostrar detalles',
        devices: {
          allDevices: 'Todos los dispositivos',
          desktop: 'Desktop',
          mobile: 'Móvil',
          tablet: 'Tableta',
          other: 'Otro'
        },
        times: {
          allTime: 'Todo el tiempo',
          today: 'Hoy',
          thisWeek: 'Esta semana',
          thisMonth: 'Este mes',
          thisYear: 'Este año'
        },
        statsSchema: {
          views: 'Vistas',
          starts: 'Inicios',
          responses: 'Respuestas',
          completionRate: 'Tasa de completado',
          timeToComplete: 'Tiempo para completar'
        }
      },
      insights: {
        questions: 'Preguntas',
        insights: 'Insights',
        fieldAnswerOk: 'Nuestra IA está emocionada de analizar estas respuestas por ti. Mientras tanto, puedes centrarte en cosas que realmente importan, como dominar el arte de dormir con los ojos abiertos. 😴😅',
        fieldAnswerLeastFive: 'Podrás obtener análisis generados por IA tan pronto como este formulario reciba al menos 5 respuestas.',
        error: {
          loadThisTime: 'Ups, no pudimos cargar los análisis en este momento. Por favor, inténtalo nuevamente en unos momentos.',
          empty: 'Aquí podrás obtener análisis de tus respuestas tan pronto como crees campos y recibas respuestas.',
          loadThisForm: 'No fue posible cargar los análisis para este formulario. Por favor, inténtalo nuevamente más tarde.'
        },
        notification: {
          success: '¡Análisis creado exitosamente!',
          error: 'Ups, ocurrió un error. Por favor, inténtalo nuevamente en unos momentos.'
        }
      },
      responses: {
        download: 'Descargar',
        waiting: 'Leyendo respuestas...',
        tooltipDownload: 'Descargar todas las respuestas...'
      }
    }
  },
  workspace: {
    createForm: 'Crear formulario',
    successfully: ' ¡Creado exitosamente!',
    form: 'Formulario '
  },
  me: {
    billing: 'Facturación',
    plan: 'Plan:',
    payment: 'Pago:',
    currency: 'Moneda:',
    cycle: 'Utilizado en este ciclo',
    languageTitle: 'Idioma',
    languageMessage: 'Aquí puede cambiar el idioma de la plataforma.',
    languageLabel: 'Seleccione el idioma',
    integrations: 'Integraciones',
    apiTokenMessage: 'Utiliza el siguiente Token de API para permitir el acceso de integración a tu cuenta.',
    copy: 'Copiar',
    loginLink: 'Enlace de inicio de sesión',
    tooltipCopy: 'Copiar para a área de transferência.',
    revokeApiToken: 'Revocar Token de API',
    someIntegrations: 'Algunas de nuestras integraciones requieren un token de API para acceder a tu cuenta.',
    ifYouWant: 'Si deseas otorgar acceso a dicho servicio, utiliza el botón de abajo para generar un token de API.',
    generateApiToken: 'Generar Token de API',
    supportAccess: 'Acceso de soporte',
    youAreCurrently: 'Actualmente estás ',
    allowing: 'permitiendo ',
    ourSupport: 'que nuestro equipo de soporte tenga acceso a tu cuenta.',
    clickTheButton: 'Haz clic en el botón de abajo para revocar este acceso.',
    not: 'no ',
    enableSupportAccess: 'Habilitar acceso de soporte',
    revokeSupportAccess: 'Revocar acceso de soporte',
    errorState: 'Ups, no pudimos cargar la información del perfil de usuario en este momento. Por favor, inténtalo nuevamente en unos momentos.',
    notification: {
      copySuccess: '¡Copiado al portapapeles exitosamente!',
      copyError: 'Tu navegador no admite la función de copiar al portapapeles.',
      apiTokenGeneratedSuccess: '¡Token de API generado exitosamente!',
      apiTokenGeneratedError: 'No fue posible generar tu token de API en este momento. Por favor, inténtalo de nuevo.',
      apiTokenRevokedSuccess: '¡Token de API revocado!',
      apiTokenRevokedError: 'No fue posible revocar tu token de API en este momento. Por favor, inténtalo de nuevo.',
      supportAccessGrantedSuccess: '¡Acceso de soporte concedido exitosamente!',
      supportAccessGrantedError: 'No fue posible permitir el acceso de soporte en este momento. Por favor, inténtalo de nuevo.',
      supportAccessRevokedSuccess: '¡Acceso de soporte revocado!',
      supportAccessRevokedError: 'No fue posible denegar el acceso de soporte en este momento. Por favor, inténtalo de nuevo.'
    },
    addOns: {
      title: 'Add-ons',
      additionalResponses: {
        title: 'Respuestas adicionales',
        subtitle: 'Seguir recibiendo respuestas al llegar al límite del plan.',
        description: 'Puede marcarla para seguir recibiendo respuestas después de que su plan alcance el límite de respuestas. Las respuestas que se excedan se cobrarán automáticamente según su plan.',
        additionalResponsesEnabled: '¡Respuestas adicionales habilitadas!',
        additionalResponsesDisabled: '¡Respuestas adicionales revocadas!',
        error: 'No ha sido posible actualizar este complemento en este momento. Vuelva a intentarlo más tarde.'
      }
    },
    dangerZone: {
      title: 'Zona de peligro',
      subtitle: 'Aquí puedes cancelar tu cuenta y eliminar todos tus datos.',
      cancelAccount: 'Cancelar cuenta'
    }
  },
  layouts: {
    error: {
      title: '¡Ups! 😬',
      message: 'Entendemos, entendemos, cometimos un error. Puedes contactarnos en ',
      and: '¡y podemos ayudarte!',
      home: 'Página de inicio'
    }
  },
  components: {
    account: {
      ModalAffiliate: {
        affiliate: 'Afiliado',
        sharingLink: 'Gana un 20% a un 30% de ingresos recurrentes al compartir tu enlace de referencia con tu audiencia, amigos, clientes, etc.',
        copy: 'Copiar',
        copyToClipboard: 'Copiar al portapapeles',
        error: 'Oops, no pudimos cargar la información de afiliados en este momento. Por favor, inténtalo de nuevo en unos minutos.',
        notification: {
          success: '¡URL copiada al portapapeles!',
          error: 'Tu navegador no admite la función de copiar al portapapeles.'
        }
      },
      modalPlans: {
        selectNewPlan: 'Selecciona tu nuevo plan.',
        per: 'por ',
        pay: 'Pagar:',
        monthly: 'Mensual',
        yearly: 'Yearly',
        select: 'Anual',
        yourCurrentPlan: 'tu plan actual',
        error: 'Ups, no pudimos cargar la información de afiliados en este momento. Por favor, inténtalo de nuevo en unos minutos.',
        plans: {
          singleProfessional: '<b>20k</b> respuestas/mensuales',
          singlePlus: '<b>2k</b> respuestas/mensuales',
          singleStarter: '<b>200</b> respuestas/mensuales',
          singleFree: '<b>200</b> respuestas/mensuales',
          unlimited: 'Formularios ilimitados',
          customEndingScreens: 'Pantallas de finalización personalizadas',
          automaticKeyword: 'Extracción automática de palabras clave',
          sentimentDetection: 'Detección de sentimientos',
          emotionDetection: 'Detección de emociones'
        }
      }
    },
    auth: {
      theSocialButtons: {
        or: 'o'
      }
    },
    common: {
      oneTimePassword: {
        wait: 'Espera hasta que el usuario ingrese el número de caracteres requerido.',
        invalid: 'Datos pegados no válidos'
      },
      alertDialog: {
        ok: '¡Ok lo tengo!'
      },
      closeButton: {
        close: 'Cerca'
      },
      confirmationDialog: {
        wait: '¡Espera un minuto!',
        danger: 'peligro',
        no: 'No',
        yes: 'Sí'
      },
      errorNotification: {
        default: 'No se puede realizar la operación.',
        close: 'Cerca'
      },
      inlineEditor: {
        tooltip: 'Haz click para editar'
      },
      stateBlock: {
        default: 'Vaya, se produjo un error. Vuelva a intentarlo en unos pocos.'
      },
      successNotification: {
        default: '¡Operación realizada con éxito!'
      },
      UndoNotification: {
        default: '¡Acción realizada con éxito!',
        close: 'Cerca'
      },
      UpgradeBadge: {
        available: 'Esta característica está disponible en los siguientes planes:',
        pleaseUpgrade: '.<br> Por favor, actualice su plan para usarlo.',
        upgradeYourPlan: 'Actualice su plan para usar esta función.'
      },
      userAvatar: {
        owner: 'dueño'
      },
      userMiniProfile: {
        remove: 'Eliminar',
        fromWorkspace: 'desde el espacio de trabajo...',
        revokeAccess: 'Retirar el acceso a este espacio de trabajo'
      }
    },
    formEdit: {
      fieldTypes: {
        calendly: {
          invalid: 'El calendario aparecerá tan pronto como se proporcione una URL válida de Calendly.',
          enablePrefill: 'Activar prellenado',
          name: 'Nombre',
          email: 'Correo electrónico',
          customField: 'Campo personalizado',
          deleteCustomField: 'Eliminar campo',
          deleteFieldTooltip: 'Eliminar campo personalizado de prellenado',
          addFieldTooltip: 'Agregar prellenado para campo personalizado',
          nameField: 'Campo de nombre...',
          emailField: 'Campo de correo electrónico...',
          customFieldLabel: 'Campo personalizado...'
        },
        date: {
          PreviewOnly: 'Vista previa solamente 👀',
          enter: 'Enter ↵',
          press: 'presionar',
          day: 'Día',
          month: 'Mes',
          year: 'Año',
          dateFormat: 'Formato de fecha'
        },
        dropdown: {
          selectAnOption: 'Selecciona una opción...',
          choices: 'Opciones',
          addChoice: 'Agregar opción',
          editChoices: 'Editar opciones',
          oneItem: 'Un elemento por línea',
          onlyUniqueItems: 'Por favor, elimine los elementos duplicados.',
          minCount: 'Debe ingresar al menos 2 opciones.',
          cancel: 'Cancelar',
          notificationSuccess: '¡Opciones guardadas!',
          title: '¡Manos arriba!',
          messageFollowingChoices: 'Las siguientes opciones se utilizan en reglas lógicas.',
          messageWeAdvice: 'Si continúas, te recomendamos revisar tus reglas lógicas, ya que se romperán.<br>¿Estás seguro de que deseas guardar las nuevas opciones?',
          noButtonText: 'Cancelar',
          yesButtonText: 'Guardar opciones',
          randomized: 'Aleatorizado.',
          alphabetically: 'Orden alfabético'
        },
        email: {
          PreviewOnly: 'Vista previa solamente 👀',
          enter: 'Enter ↵',
          press: 'presionar'
        },
        endingScreen: {
          thisEnding: 'Esta pantalla de finalización no se mostrará, en su lugar, el usuario será redirigido a:',
          enter: 'Enter ↵',
          press: 'presionar',
          sayBye: 'Di adiós aquí.',
          callToAction: 'Mostrar botón',
          buttonText: 'Botón de texto',
          buttonLink: 'Enlace del botón',
          tooltip: 'Haz clic para editar ✍️. <br> Escribe <b>@</b> para insertar una variable.',
          reload: 'Recargar',
          help: 'Redirige automáticamente al usuario a otra página al completar.',
          redirectTo: 'Redirigir a'
        },
        longText: {
          previewOnly: 'Vista previa solamente 👀',
          typeYourAnswer: 'Escribe tu respuesta aquí...',
          toMakeLineBreak: 'para hacer un salto de línea',
          press: 'presionar ',
          keywordExtraction: 'Extracción de palabras clave',
          TextAnalysis: 'Análisis de texto',
          enablesAutomaticKeyword: 'Habilita la extracción automática de palabras clave<br> de las respuestas.',
          automaticallyDetectEmotion: 'Detecta automáticamente la emoción  <br>(amor, alegría, sorpresa, tristeza, enojo y miedo)<br> y el sentimiento (👍 👎) de las respuestas.',
          MaxCharacters: 'Máximo de caracteres.'
        },
        multipleChoice: {
          chooseMany: 'Elige tantas como quieras',
          option: 'Opción...',
          removeChoice: 'Quitar opción',
          other: 'Otra',
          addChoice: 'Añadir opción',
          error: 'Parece que la opción que estás intentando agregar ya existe. Por favor, cambia el nombre de la opción actual para agregar otra.',
          confirmDeletion: 'Esta opción se usa en reglas lógicas.<br>Si continúa, romperá la lógica.<br><br>¿Está seguro de que desea eliminarlo?',
          multipleSelection: 'Selección múltiple',
          randomized: 'Aleatoria',
          unlimited: 'Ilimitado',
          exactNumber: 'Numero exacto',
          range: 'Rango'
        },
        pictureChoice: {
          multipleSelection: 'Selección múltiple',
          randomized: 'Aleatoria',
          superSize: 'Súper tamaño'
        },
        number: {
          previewOnly: 'Vista previa solamente 👀',
          typeYourAnswer: 'Escribe tu respuesta aquí...',
          press: 'presionar ',
          decimal: 'Decimal',
          decimalPlaces: 'Lugares decimales',
          decimalSeparator: 'Separador decimal',
          prefix: 'Prefijo',
          suffix: 'Sufijo'
        },
        opinionScale: {
          previewOnly: 'Vista previa solamente 👀',
          fromLabel: 'Etiqueta de',
          toLabel: 'Etiquetar hasta',
          dontLike: 'Por ejemplo, no me gusta eso',
          loveIt: 'Por ejemplo, ¡adoro eso!',
          from: 'En',
          to: 'hasta'
        },
        phone: {
          previewOnly: 'Vista previa solamente 👀',
          press: 'presionar ',
          defaultCountryCode: 'Código de país por defecto'
        },
        rating: {
          previewOnly: 'Vista previa solamente 👀',
          items: 'artículos',
          icon: 'ícono',
          star: 'estrella',
          heart: 'corazón',
          user: 'usuario',
          up: 'pulgar hacia arriba',
          tick: 'Calificación',
          crown: 'Corona',
          trophy: 'trofeo'
        },
        shortText: {
          previewOnly: 'Vista previa solamente 👀',
          typeYourAnswer: 'Escribe aquí tu respuesta...',
          press: 'presionar ',
          enablesAutomaticKeyword: 'Permite la extracción automática de palabras clave de las respuestas.',
          automaticallyDetectEmotion: 'Detecta automáticamente la emoción <br> (amor, alegría, sorpresa, tristeza, enojo y miedo) y el sentimiento (👍 👎) en las respuestas.',
          keywordExtraction: 'Extracción de palabras clave',
          TextAnalysis: 'Análisis de texto',
          MaxCharacters: 'Máximo de caracteres'
        },
        statement: {
          sayHiHere: '¡Saluda aquí!',
          press: 'presionar ',
          continue: 'Continuar',
          hideButton: 'Ocultar botón',
          button: 'Botón'
        },
        website: {
          previewOnly: 'Vista previa solamente 👀',
          press: 'presionar '
        },
        welcomeScreen: {
          start: 'Comenzar',
          button: 'Botón'
        },
        yesNo: {
          y: 'S',
          n: 'N',
          yes: 'Sí',
          no: 'No'
        }
      },
      fieldHeader: {
        clickToEdit: 'Haz clic para editar ✍️.<br> ',
        toInsert: 'para insertar una variable.',
        atSign: 'Escribe lo que desees. @ ',
        placeholder: 'Descripción (opcional)',
        titlePlaceholder: 'Tu pregunta aquí.',
        align: 'izquierda'
      },
      fieldListItem: {
        duplicate: 'Duplicar',
        delete: 'Eliminar...',
        dupDel: 'Duplicar, Eliminar...'
      },
      fieldRequired: {
        required: 'Obligatorio'
      },
      fieldSettingSwitch: {
        upgradeYourPlan: '<b>Realice la actualización de su plan para utilizar esta función.</b>'
      },
      formVariable: {
        clickToEdit: 'Haz click para editar'
      },
      LogicActionEditor: {
        clickToChoose: 'Haz clic para elegir otro campo.'
      },
      logicConditionEditor: {
        clickToChoose: 'Haz clic para elegir un campo.',
        variables: 'Variables',
        fields: 'Campos',
        addNewCondition: 'añadir nueva condición',
        condition: '+ condición',
        delete: 'Eliminar',
        deleteThisCondition: 'Eliminar esta condición',
        if: 'SI'
      },
      logicLogicalOperator: {
        or: 'o',
        and: 'y'
      },
      logicRuleEditor: {
        then: 'Entonces',
        deleteThisRule: 'Eliminar esta regla',
        delete: 'Eliminar',
        deleteAction: 'Eliminar acción',
        deleteThisAction: 'Eliminar esta acción',
        addAction: 'Agregar acción'
      },
      logicRuleItem: {
        if: 'SI',
        clickToEdit: 'Haz clic para editar esta regla'
      },
      mediaRenderer: {
        remove: 'quitar'
      },
      modalAddMedia: {
        addImage: 'Añadir imagen o vídeo',
        selectImageToUpload: 'Seleccione una imagen de hasta 2 MB.',
        allowedExtensions: 'Extensiones permitidas: svg, jpeg, png, jpg, gif.',
        optimizingTheFile: 'Optimización del archivo para la web.',
        pleaseWait: 'Espere por favor...',
        egImage: 'Por ejemplo. https://tusitio.com/logo.png',
        enterUrlImage: 'Ingrese la URL de la imagen que desea incluir en su formulario.',
        egYoutube: 'Por ejemplo. https://www.youtube.com/watch?v=L_LUpnjgPso',
        enterValidImageUrl: 'Introduzca una URL de imagen válida.',
        enterUrlVideo: 'Introduce la URL del vídeo de Youtube, Vimeo o Panda Video.',
        enterValidYoutube: 'Introduce una URL válida de Youtube o Vimeo.',
        cancel: 'cancelar',
        add: 'agregar',
        imageUpload: 'Carga de imagen',
        imageUrl: 'URL de la imagen',
        gallery: 'Galería',
        areYouSure: '¿Seguro que quieres eliminar esta foto de tu galería?',
        errorMedia: 'La imagen debe ser de tipo: png, jpg, jpeg, gif, con un tamaño máximo de 2MB.',
        errorUpload: 'Lo siento, no se pudo completar la carga',
        errorDelete: 'Lo siento, no se pudieron eliminar los medios',
        errorFind: 'Lo sentimos, no fue posible buscar los medios',
        emptyGallery: 'Sus imágenes aparecerán aquí tan pronto como las cargue.'
      },
      modalEditVariables: {
        variables: 'Variables',
        variablesAllow: 'Las variables te permiten realizar cálculos personalizados sobre reglas lógicas basadas en las respuestas de tus formularios.',
        defaultVariables: 'Variables predeterminadas',
        customVariables: 'Variables personalizadas',
        matches: 'Solo se permiten letras, números y guiones bajos',
        unique: 'Este nombre de variable ya está en uso',
        removeVariable: 'Quitar variable',
        addVariable: 'Añadir variable',
        close: 'Cerca',
        confirmDeletionLogic: 'Esta variable se utiliza en las reglas lógicas.<br><br>¿Está seguro de que desea eliminar esta variable y las reglas lógicas que la utilizan?',
        confirmDeletionContent: 'Esta variable se está utilizando en el contenido de una pregunta.<br><br>¿Desea eliminar esta variable y eliminar todas sus apariciones de las preguntas que la utilizan?',
        message: 'Hay errores en el formulario.<br>¿Cerrar de todos modos?',
        variant: 'primario',
        noButtonText: 'No, déjame arreglarlo.',
        yesButtonText: 'Sí, cerrar sin guardar'
      },
      richInputSuggestion: {
        variables: 'Variables',
        notFoundVariables: 'No se encontraron variables.'
      },
      theFieldLayout: {
        imageVideo: 'Imagen / Vídeo',
        add: '+ Agregar',
        remove: 'Eliminar',
        onTheTop: 'No topo',
        onTheMiddle: 'No meio',
        height: 'Altura',
        width: 'Ancho',
        auto: 'auto',
        altText: 'Texto alternativo',
        imageDescription: 'descripción de la imagen'
      },
      theFieldList: {
        welcomeScreen: 'Pantalla de bienvenida',
        addWelcomeScreen: 'Agregar pantalla de bienvenida',
        questions: 'Preguntas',
        AddQuestion: 'Agregar pregunta',
        endingScreens: 'Pantallas finales',
        AddEndingScreens: 'Agregar pantalla final',
        confirmDeletionLogic: 'Esta pregunta tiene reglas lógicas.<br>Si continúa, romperá la lógica.<br><br>¿Está seguro de que desea eliminarla?',
        confirmDeletionOtherLogic: 'Esta pregunta se utiliza en las reglas lógicas de otras preguntas. Si continúa, romperá la lógica.<br><br>¿Está seguro de que desea eliminarlo?',
        confirmResponsesLossEnding: 'Si continúa, perderá la cantidad de vistas y abandonos asociados. ¿Está seguro de que desea eliminarlo?',
        confirmResponsesLossWelcome: 'Esta pregunta ya tiene respuestas. Si continúa, perderá todas sus respuestas. Le recomendamos que descargue las respuestas antes de continuar. ¿Está seguro de que desea eliminar este campo y todas sus respuestas?'
      },
      theFieldPreview: {
        chooseOrAdd: '¡Claro! Estoy aquí para ayudarte. Por favor, proporciona el texto que deseas que traduzca al español, y con gusto te brindaré la traducción.'
      },
      theFieldSettings: {
        welcome: 'La pantalla de bienvenida y finalización no se puede cambiar.La pantalla de bienvenida y finalización no puede modificarse.',
        type: 'Tipo',
        settings: 'Ajustes',
        select: 'Seleccione una pregunta para mostrar su configuración.',
        alert: 'Esta pregunta ya tiene respuestas, por lo que no puedes cambiar su tipo en este momento. En su lugar, te recomendamos descargar las respuestas, eliminar el campo y agregar uno nuevo del tipo que necesites.'
      },
      theFormLeftMenu: {
        content: 'Contenido',
        design: 'Diseño',
        settings: 'Ajustes'
      },
      theFormRightMenu: {
        question: 'Pregunta',
        logic: 'Lógica'
      },
      theFormSettings: {
        formSettings: 'Configuración de formulario',
        language: 'Idioma',
        theLanguageToShow: 'El idioma para mostrar los botones, pistas y mensajes de error del formulario.',
        integrationsSettings: 'Configuración de integraciones',
        hidePowered: 'Ocultar Impulsado por Yay! Formularios',
        removeTheCredits: 'Eliminar los créditos mostrados en el pie de página de tus formularios.',
        closeForm: 'Cerrar formulario a nuevas respuestas',
        sendIncomplete: 'Enviar respuestas incompletas',
        enableSending: 'Habilite el envío de respuestas incompletas a la plataforma integrada.<br>Si esta configuración está activada, debe especificar un retraso (en minutos).<br>Este retraso indica el tiempo de espera antes de que se envíe la respuesta incompleta.',
        waitingTime: 'Tiempo de espera en minutos.',
        aiFeedback: 'Habilitar comentarios de IA',
        aiFeedbackdHelp: 'Habilite la generación de comentarios de IA para las respuestas de los usuarios.',
        prompt: {
          label: 'Prompt de IA',
          placeholder: 'Por ejemplo, si la puntuación del usuario es inferior a 5, aconseje al usuario sobre cómo mejorarla basándose en sus respuestas.',
          help: 'Configure un prompt para generar comentarios personalizados sobre las respuestas con inteligencia artificial. <a href="https://help.yayforms.com/article/182-guide-to-placeholders-for-email-and-messages-customization" target="_blank"><b><u>Learn more</u></b></a>.'
        }
      },
      theFormVariables: {
        variables: 'Variables',
        removeVariable: 'Quitar variable',
        addVariable: 'Añadir variable',
        selectAQuestion: 'Seleccione una pregunta para mostrar sus variables.'
      },
      theLogicRuleList: {
        variables: 'Variables',
        createVariables: 'Crear variables para usar en tus reglas lógicas.',
        pleaseSelect: 'Por favor, selecciona una pregunta en el panel izquierdo para agregar una nueva regla lógica, ya que no se pueden crear reglas para las pantallas de finalización.',
        rules: 'Reglas',
        theFollowingRules: 'Las siguientes reglas se aplicarán cuando alguien responda la pregunta.',
        else: 'EN OTRO CASO',
        addRule: 'Añadir regla',
        byDefault: 'Por defecto',
        otherwise: 'En caso contrario',
        goTo: 'Ir a',
        selectAQuestion: 'Select a question to show its logic rules.'
      },
      themeListItem: {
        clickToSelect: 'Haz clic para seleccionar este tema.',
        question: 'Pregunta',
        answer: 'Respuesta',
        thisTheme: 'Este tema ya está en uso para este formulario.',
        selectThisTheme: 'Seleccione este tema.',
        editFont: 'Editar la fuente y los colores de este tema.',
        duplicate: 'Duplicar este tema',
        thisThemeIsBeing: 'Este tema está siendo utilizado por este formulario. Por favor, selecciona otro tema si deseas eliminar este.',
        deleteThisTheme: 'Eliminar este tema'
      },
      theThemeEditor: {
        goBack: 'Regresa',
        themeName: 'Nombre del tema',
        font: 'Fuente',
        colors: 'Colores',
        revert: 'Revertir',
        save: 'Ahorrar',
        title: '¿Descartar los cambios?',
        message: 'Hay cambios sin guardar en este tema. ¿Estás seguro de que deseas descartarlos?',
        noButtonText: 'Cancelar',
        yesButtonText: 'Sí, descartar cambios',
        discardChangesTooltip: 'Descartar cambios',
        saveChangesTooltip: 'Guardar cambios en el tema'
      },
      theThemeList: {
        themes: 'Temas',
        newTheme: 'Nuevo tema',
        confirmDeletion: '¿Estás seguro de que quieres eliminar el tema?'
      }
    },
    formIntegrate: {
      credentialsDropdown: {
        connect: 'Conectar ',
        account: ' cuenta',
        loading: 'Cargando ',
        accounts: ' cuentas',
        connectOther: 'Conectar otro ',
        authorize: '¡Autorizar Yay! forms'
      },
      integrationCard: {
        active: 'Activo',
        inactive: 'Inactivo',
        tooltip: 'Haga clic para activar o desactivar esta integración.',
        by: 'Por',
        or: 'o',
        disabledTip: 'Esta integración ha sido deshabilitada debido a un error o por decisión del propietario del formulario. Por favor, edite los ajustes de la integración y guarde de nuevo para habilitar la integración.',
        configure: 'Configurar',
        settings: 'Ajustes',
        test: 'Probar',
        gettingStarted: 'Comenzando',
        copyToClipboard: 'Copiar al portapapeles',
        copy: 'Copiar',
        itSeemsLike: 'Parece que aún no tienes un token de API.',
        generate: 'Generar token de API',
        see: 'Ver la guía de',
        guide: '',
        open: 'abrir',
        yourCurrentPlan: 'Tu plan actual ',
        doesntAllow: ' no te permite integrarte con ',
        pleaseUpgrade: ' Por favor, actualiza tu plan para continuar.',
        integration: ' Integración',
        notification: {
          apiTokenCopiedSuccess: '¡Token de API copiado al portapapeles!',
          apiTokenCopiedError: 'Tu navegador no admite la función de copiar al portapapeles.',
          apiTokenGeneratedSuccess: '¡Token de API generado exitosamente!',
          apiTokenGeneratedError: 'No fue posible generar tu token de API en este momento. Por favor, inténtalo nuevamente.',
          testSuccess: '¡Prueba enviada con éxito!',
          testError: 'No ha sido posible enviar la prueba en este momento. Vuelva a intentarlo más tarde.'
        }
      },
      modalAddWebhook: {
        title: 'Nuevo webhook',
        name: 'Nombre',
        egSendResponse: 'Por ejemplo, enviar una respuesta a Slack.',
        aNameToHelpYouRemember: 'Un nombre para ayudarte a recordar para qué se utiliza este webhook.',
        endpoint: 'punto final',
        sendDataTo: 'Enviaremos datos a esta URL.',
        yourWebsite: 'https://supaginaweb.com/webhook',
        labelSecret: 'Secreto',
        placeholderSecret: 'Mi secreto...',
        helpSecret: 'Si se especifica, se utilizará para firmar el payload del webhook con HMAC SHA256, de modo que puedas verificar que proviene de Yay! Forms.',
        labelSsl: 'Verificación SSL',
        helpSsl: 'Verifique si deseas que Yay! Forms verifique los certificados SSL al entregar los payloads.',
        LabelHeader: 'Cabecera personalizada',
        placeholderHeaderName: 'Nombre del encabezado...',
        placeholderHeaderValue: 'Valor de encabezado...',
        helpHeaderName: 'Si se especifica, se agregará un encabezado personalizado al payload del webhook.',
        labelTriggerCondition: 'Condición de activación',
        placeholderTriggerCondition: 'Ej. {{id_de_campo}} CONTAINS "sí" AND {{id_de_campo}} >= "25000"',
        helpTriggerCondition: 'La fórmula que especifica cuándo enviar el webhook. Dejar en blanco para enviar siempre el webhook cuando se envíe el formulario.<a href="https://help.yayforms.com/article/205-how-to-create-expressions-for-conditional-execution-of-integrations" target="_blank"><b><u>Más información</u></b ></a>.',
        labelCaseInsensitive: 'Condición de activación que no distingue entre mayúsculas y minúsculas',
        helpCaseInsensitive: 'Marque esta casilla si desea que la condición de activación no distinga entre mayúsculas y minúsculas. Si se marca, expresiones como "Acepto" o "acepto" se considerarán iguales.',
        cancel: 'Cancelar',
        save: 'Ahorrar',
        webhook: '¡Webhook guardado exitosamente!'
      },
      modalConfigureOauthIntegration: {
        disconnect: 'Desconectar',
        cancel: 'Cancelar',
        saveIntegration: 'Ahorrar integración',
        saving: 'Ahorro...',
        integration: 'Integración de {integrationName}',
        confirmDeletion: '¿Estás seguro de que quieres eliminar el',
        notification: {
          integrationSavedSuccess: ' ¡La integración se guardó con éxito!',
          disconnectSuccess: ' desconectado exitosamente!'
        }
      },
      ModalConfigureWebhookIntegration: {
        disconnect: 'Desconectar',
        cancel: 'Cancelar',
        saveIntegration: 'Ahorrar',
        confirmDeletion: '¿Estás seguro de que quieres eliminar el',
        integration: ' Integración',
        notification: {
          integrationSavedSuccess: ' ¡La integración se guardó con éxito!',
          disconnectSuccess: ' desconectado exitosamente!'
        }
      },
      modalEditWebhook: {
        title: 'Editar webhook',
        LabelName: 'Nombre',
        placeholderName: 'E.g. Send response to Slack',
        helpName: 'Un nombre para ayudarte a recordar qué hace este webhook.',
        labelUrl: 'Endpoint',
        placeholderUrl: 'Nuevo endpoint...',
        helpUrl: 'Enviaremos los datos a esta URL.',
        labelSecret: 'Secreto',
        placeholderSecret: 'Mi secreto...',
        helpSecret: 'Si se especifica, se utilizará para firmar el payload del webhook con HMAC SHA256, de modo que puedas verificar que proviene de Yay! Forms.',
        labelSsl: 'Verificación SSL',
        helpSsl: 'Verifique si deseas que Yay! Forms verifique los certificados SSL al entregar los payloads.',
        LabelHeader: 'Cabecera personalizada',
        placeholderHeaderName: 'Nombre del encabezado...',
        placeholderHeaderValue: 'Valor de encabezado...',
        helpHeaderName: 'Si se especifica, se agregará un encabezado personalizado al payload del webhook.',
        labelTriggerCondition: 'Condición de activación',
        placeholderTriggerCondition: 'Ej. {{id_de_campo}} CONTAINS "sí" AND {{id_de_campo}} >= "25000"',
        helpTriggerCondition: 'La fórmula que especifica cuándo enviar el webhook. Dejar en blanco para enviar siempre el webhook cuando se envíe el formulario.<a href="https://help.yayforms.com/article/205-how-to-create-expressions-for-conditional-execution-of-integrations" target="_blank"><b><u>Más información</u></b ></a>.',
        labelCaseInsensitive: 'Condición de activación que no distingue entre mayúsculas y minúsculas',
        helpCaseInsensitive: 'Marque esta casilla si desea que la condición de activación no distinga entre mayúsculas y minúsculas. Si se marca, expresiones como "Acepto" o "acepto" se considerarán iguales.',
        cancel: 'Cancelar',
        save: 'Ahorrar'
      },
      modalSmtp: {
        title: 'Configuración de SMTP',
        host: 'Host',
        hostLabel: 'Host',
        hostPlaceholder: 'Introduce tu host SMTP',
        hostHelp: 'La dirección de tu servidor SMTP.',
        port: 'Puerto',
        portLabel: 'Puerto',
        portPlaceholder: 'Introduce tu puerto SMTP',
        portHelp: 'El puerto que utiliza tu servidor SMTP (usualmente 587 para TLS).',
        encryption: 'Encriptación',
        encryptionLabel: 'Encriptación',
        encryptionNone: 'Ninguna',
        encryptionSSL: 'SSL',
        encryptionTLS: 'TLS',
        encryptionHelp: 'El método de encriptación para asegurar tu conexión SMTP.',
        username: 'Usuario',
        usernameLabel: 'Usuario',
        usernamePlaceholder: 'Introduce tu usuario SMTP',
        usernameHelp: 'El nombre de usuario de tu cuenta SMTP.',
        password: 'Contraseña',
        passwordLabel: 'Contraseña',
        passwordPlaceholder: 'Introduce tu contraseña SMTP',
        passwordHelp: 'La contraseña de tu cuenta SMTP.',
        from: 'De',
        fromLabel: 'Dirección de email',
        fromPlaceholder: 'Introduce tu dirección de email remitente',
        fromHelp: 'La dirección de email desde la cual se enviarán los correos.',
        name: 'Nombre',
        nameLabel: 'Nombre',
        namePlaceholder: 'Introduce tu nombre',
        nameHelp: 'El nombre que aparecerá como remitente.',
        cancel: 'Cancelar',
        save: 'Probar y guardar',
        success: '¡Configuración de SMTP guardada exitosamente!',
        testing: 'Probando...'
      },
      theIntegrationNavbar: {
        integrations: 'Integraciones'
      },
      webhookListItem: {
        active: 'Activo',
        inactive: 'Inactivo',
        tooltip: 'Editar, Eliminar...',
        edit: 'Editar',
        delete: 'Eliminar',
        test: 'Probar',
        confirmDelete: '¿Estás seguro de que deseas eliminar este webhook?'
      }
    },
    formResults: {
      emotions: {
        love: 'amor',
        joy: 'alegría',
        surprise: 'sorpresa',
        sadness: 'tristeza',
        anger: 'enojo',
        fear: 'miedo'
      },
      sentiments: {
        positive: 'positivo',
        neutral: 'neutro',
        negative: 'negativo'
      },
      answerBars: {
        showing: 'Mostrando ',
        answersFromSubmitted: ' respuestas de formularios enviados',
        onlyAnswers: 'Solo se muestran las respuestas de los formularios enviados aquí.',
        answers: 'respuestas'
      },
      answerList: {
        emotions: 'Emociones',
        sentiments: 'Sentimientos',
        keywords: 'Palabras clave',
        messageKeywordsWill: 'Las palabras clave se mostrarán aquí tan pronto como este formulario obtenga un mínimo de respuestas.',
        copyKeywords: 'Copiar palabras clave al portapapeles',
        copy: 'Copiar',
        occurrencesFor: ' ocurrencias para ',
        answers: 'Respuestas',
        messageNoAnswers: 'No se encontraron respuestas con el filtro actual.',
        loading: 'Cargando...',
        notificationSuccess: '¡Palabras clave copiadas al portapapeles!',
        notificationError: 'Tu navegador no admite copiar al portapapeles.'
      },
      answerVerticalBars: {
        showing: 'Mostrando ',
        answersFromSubmitted: ' respuestas de formularios enviados',
        onlyAnswers: 'Solo se muestran las respuestas de los formularios enviados aquí.',
        answers: 'respuestas',
        npsTooltip: 'NPS = % Promotores - % Detractores'
      },
      emojiBadge: {
        value: 'valor',
        answers: 'respuestas'
      },
      fieldSummary: {
        uniqueViews: 'Vistas únicas',
        views: 'Vistas',
        itIncludes: 'Incluye respuestas de formularios enviados y no enviados.',
        answers: 'respuestas',
        peopleWho: 'Personas que abandonaron la respuesta al formulario en esta pregunta.',
        DropOff: 'Dejar',
        resultsWillAppears: 'Los resultados aparecerán aquí tan pronto como alguien responda este formulario.',
        weCouldntLoad: 'Ups, no pudimos cargar las respuestas en este momento. Por favor, intenta nuevamente en unos minutos.'
      },
      fileUploadCellRenderer: {
        view: 'Ver',
        alert: '¡Error al descargar el archivo!'
      },
      InboxItem: {
        notAnswered: 'No respondido',
        deleteResponse: 'Eliminar respuesta',
        createdAt: 'Criado en:',
        submittedAt: 'Presentado en:'
      },
      MarkdownCellRenderer: {
        showLess: 'Mostrar menos',
        showMore: 'Mostrar más'
      },
      modalGenerateInsight: {
        generateInsight: 'Generar insights',
        currently: 'Actualmente, tenemos un límite en la cantidad de datos que podemos examinar a la vez. Por lo tanto, selecciona el rango del cual deseas obtener insights y haremos nuestro mejor esfuerzo para analizar tantas respuestas como sea posible dentro de ese rango.',
        labelInterval: '¿Qué intervalo le gustaría analizar?',
        labelWhatKind: '¿Qué tipo de insight te gustaría obtener?',
        TooltipSelect: 'Selecciona una receta para generar insights.',
        select: 'Seleccionar...',
        resultsPreview: 'Vista previa de resultados',
        inThisRange: 'En este rango, analizaremos <strong>{analyze}</strong> respuestas de <strong>{generate}</strong> para generar insights',
        usingRecipe: 'utilizando la receta',
        cancel: 'Cancelar',
        theInsight: 'La creación de la insights ha comenzado...'
      },
      textAnswerRender: {
        tooltipEmotion: 'Todavía estamos analizando la emoción de este texto. Estará disponible pronto.',
        tooltipSentiment: 'Todavía estamos analizando el sentimiento de este texto. Estará disponible pronto.'
      },
      theFieldInsightsPanel: {
        generateNewInsight: 'Generar insights...',
        generateInsight: 'Generar insights',
        generatingInsight: 'Generando insights...',
        generating: 'Generando...',
        endAt: 'Finaliza en:',
        insight: 'Insight',
        copyClipboard: 'Copiar al portapapeles',
        messageOurIa: 'Nuestra IA está emocionada de analizar estas respuestas para ti. Mientras tanto, puedes centrarte en cosas que realmente importan, como dominar el arte de dormir la siesta con los ojos abiertos. 😴😅',
        messageCouldntLoad: 'Ups, no pudimos cargar los insights en este momento. Por favor, intenta nuevamente en unos minutos.',
        pleaseWait: 'Por favor, espera hasta que se genere lo insights.',
        insightNotIf: '!Insight copiado al portapapeles!',
        yourBrowserDoesNotSupport: 'Tu navegador no admite copiar al portapapeles.'
      },
      theInboxTable: {
        loading: 'Cargando...',
        clickToChoose: 'Haz clic para elegir un campo.',
        fields: 'Campos',
        errorGetAnswers: 'No fue posible recuperar las respuestas'
      },
      theInsightFieldList: {
        questions: 'Preguntas'
      },
      theResponsesTable: {
        placeholder: 'Buscar en preguntas abiertas...',
        deleteSelected: 'Eliminar respuestas seleccionadas',
        loadingMessage: 'Cargando respuestas, por favor espera...',
        title: 'Aún no hay resultados para mostrar.',
        description: 'Los resultados se mostrarán aquí tan pronto como alguien responda tu formulario.',
        alert: 'Se debe seleccionar al menos una respuesta para eliminar.',
        confirmDeletion: '¿Estás seguro de que quieres eliminar el ',
        selectedResponse: ' ¿Respuesta(s) seleccionada(s)?',
        headerNameStarted: 'Inició en',
        headerNameSubmitted: 'Enviado/a el'
      },
      theResultsNavbar: {
        getAnOverview: 'Obtén una visión general del rendimiento y las respuestas que recibió tu formulario.',
        summary: 'Resumen',
        insightsAutomagically: 'Insights generados automáticamente para ti.',
        insights: 'Insights',
        seeAllResponses: 'Ver todas las respuestas en una tabla.',
        responses: 'Respuestas',
        seeInbox: 'Ver las respuestas individualmente.',
        inbox: 'Bandeja de entrada'
      }
    },
    formShare: {
      embedSettings: {
        embedMode: 'Modo de inserción',
        design: 'Diseño',
        hiddenFields: 'campos ocultos',
        utmParameters: 'Parámetros UTM'
      },
      modalEmbed: {
        customize: 'Personaliza e incrusta en tu sitio web',
        copyAndPaste: 'Copia y pega el código a continuación en el cuerpo de tu página, en la sección donde deseas que se muestre.',
        copyToClipboard: 'Copiar al portapapeles',
        copyEmbedCode: 'Copiar código de inserción',
        notificationSuccess: '¡Código copiado al portapapeles!',
        notificationError: 'Su navegador no admite la copia al portapapeles.'
      },
      modalWordPress: {
        title: 'Instale nuestro plugin de WordPress',
        step1: 'Descarga el plugin Yay! Forms para WordPress.',
        step2: '2. Desde tu panel de WordPress, selecciona Plugins > Añadir nuevo.',
        step3: '3. Haga clic en Cargar plugin en la parte superior de la página.',
        step4: '4. Haga clic en Elegir archivo y localice el plugin.',
        step5: '5. Una vez completada la instalación, haz clic en Activar Plugin.'
      },
      modalQrcode: {
        title: 'Guarde su QR code',
        pointYourCamera: 'Apunta tu cámara hacia este QR code para abrir este formulario. Puedes descargar este QR code para promocionar tu formulario en cualquier medio.',
        cancel: 'Cancelar',
        downloadQrCode: 'Descargar QR code'
      },
      theHiddenFields: {
        title: 'Campos ocultos',
        addMyParameter: 'Agregue cualquier parámetro, como un correo electrónico o una identificación personalizada, para ayudarlo a rastrear quién completó el formulario. Después de agregar un campo oculto, se puede enviar a través de una cadena de consulta como en el siguiente ejemplo:',
        deleteHiddenField: 'Eliminar campo oculto',
        addHiddenField: '+ Añadir campo oculto...',
        matches: 'Solo se permiten letras, números, guiones y guiones bajos.',
        validateHiddenField: 'El nombre que asignaste al campo oculto ya está en uso o necesita ser habilitado a través de una integración adecuada',
        cancel: 'Cancelar'
      },
      theUtmParameters: {
        title: 'Parámetros UTM',
        description: 'Agregue los parámetros UTM (utm_source, utm_medium, utm_campaign, utm_term, utm_content) a la URL de su formulario para que pueda realizar un seguimiento de sus usuarios.'
      }
    },
    forms: {
      modalMoveForm: {
        title: 'Mover formulario',
        save: 'Guardar',
        cancel: 'Cancelar',
        success: '¡Formulario movido exitosamente!',
        noWorkspacesAvailable: 'No hay espacios de trabajo disponibles para mover el formulario.'
      },
      formListItem: {
        duplicateDelete: 'Duplicar, eliminar ...',
        duplicate: 'Duplicar',
        move: 'Move',
        export: 'Exportar',
        delete: 'eliminar...',
        noResponsesYet: 'sin respuestas',
        live: 'Publicado',
        scheduled: 'programado',
        creating: 'creando...',
        areYouSureYouWant: '¿Está seguro de que desea eliminar el formulario? ',
        andAll: 'y todo',
        responses: ' respuestas',
        notificationSuccess: '¡URL copiada al portapapeles!',
        notificationError: 'Su navegador no admite la copia al portapapeles.',
        bySharing: 'Al compartir la siguiente URL con otros usuarios de Yay! Forms, les permites importar una copia de este formulario en su cuenta:  ',
        yourFormHasBeen: '¡Tu formulario ha sido exportado!',
        copyImport: 'Copiar enlace de importación',
        brainstormingIdeas: 'Ideas para la lluvia de ideas...',
        addingQuestions: 'Agregando preguntas...',
        puttingFinishing: 'Dando los toques finales...'
      },
      modalNewForm: {
        createForm: 'Crear un formulario...',
        tooltipIa: 'Just tell us the purpose of your form and let Artificial Intelligence do the hard work for you.',
        automagically: 'Automágicamente por IA',
        tooltipCreateForm: 'Create a form with your bear hands from scratch.',
        fromScratch: 'desde cero',
        whatIsThe: 'Cuál es el',
        specific: 'específico',
        purposeOfThisForm: 'propósito de este formulario?',
        placeholder: 'Quiero lograr lo siguiente con este formulario:',
        pleaseDescribe: 'Quiero lograr lo siguiente con este formulario.',
        createFromWith: 'Crear un formulario con asistencia de IA',
        untitledForm: 'Formulario sin título',
        notificationSuccess: 'Estamos creando tu formulario, por favor danos unos segundos...',
        words: {
          captureLeads: 'Capturar clientes potenciales para el curso de idiomas que enseño',
          imManager: 'Soy un gerente que desea recopilar comentarios de los miembros de mi equipo',
          understandTheNeeds: 'Comprender las necesidades de los clientes de una compañía de seguros',
          knowMyCustomers: 'Saber qué piensan mis clientes acerca de los servicios de mi agencia de marketing',
          performEmployee: 'Realizar una encuesta de satisfacción de empleados',
          collectFeedback: 'Recopilar comentarios de los clientes del curso que estoy enseñando',
          knowThePains: 'Conocer las dificultades de los clientes de mi restaurante de pizza'
        }
      },
      theFormNavBar: {
        goBackToWorkspace: 'Volver al espacio de trabajo',
        emptyValuePlaceholder: 'Nota sin título',
        editPlaceholder: '¿Cuál es el título?',
        edit: 'Editar',
        integrate: 'Integrar',
        share: 'Compartir',
        results: 'Resultados',
        discardChanges: 'Descartar cambios',
        discard: 'Descartar',
        saveChangesAndTurn: 'Guardar cambios y hacer público este formulario',
        publish: 'Publicar',
        title: 'Formulario sin título'
      }
    },
    layout: {
      theNavbar: {
        title: 'Inicio'
      },
      theNavbarAlert: {
        yourAccount: 'Su cuenta está suspendida. Por favor, realice un pago para reactivar su cuenta.',
        makePayment: 'Realizar un pago'
      },
      theUpgradePlanButton: {
        responsesUsed: ' respuestas utilizadas este mes en su plan',
        plan: '',
        responses: 'Respuestas',
        upgrade: 'Upgrade',
        redirecting: 'Redirigir...'
      },
      theUserAvatarDropdown: {
        profile: 'Perfil',
        earnMoney: 'Programa de afiliados',
        billing: 'Facturación',
        redirecting: 'Redirigir...',
        support: 'Apoyo',
        signOut: 'Desconectar'
      },
      theUserAvatarList: {
        show: 'Muestrar ',
        more: 'Más',
        membersOf: 'Miembros de ',
        removeFromWorkspace: 'Quitar del espacio de trabajo...',
        addUserTo: 'Agregar usuario a ',
        inviteUserTo: 'Invitar al usuario a ',
        sendingInvitation: 'Enviando invitación...',
        invite: 'Invitar',
        label: 'Correo electrónico',
        placeholder: 'Correo electrónico del usuario que deseas invitar',
        willNotAble: ' No podrán ver ni editar los formularios en este espacio de trabajo si continúas. ¿Estás seguro/a de que quieres eliminar su acceso?',
        yesRemove: 'Sí, elimina al usuario del espacio de trabajo.',
        userRemoved: '¡Usuario eliminado del espacio de trabajo!',
        invitationSent: '¡Invitación enviada!'
      },
      theWorkspaceSubdomain: {
        subdomain: 'Subdominio'
      },
      theWorkspaceOptions: {
        workspaceOptionsTooltip: 'Opciones del espacio de trabajo...',
        workspaceOptions: 'Opciones del espacio de trabajo',
        rename: 'Cambiar nombre',
        delete: 'Eliminar',
        title: 'Espera un momento',
        goingAhead: 'Continuar significa que no podrás acceder a los formularios de ',
        anymore: ' No podrás acceder más a este espacio de trabajo. ¿Estás seguro/a de que quieres abandonarlo?',
        noButtonText: 'No',
        yesButtonText: 'Sí, salir del espacio de trabajo'
      },
      theWorkspaceDropdown: {
        addWorkspace: 'Agregar espacio de trabajo...'
      }
    },
    workspace: {
      modalAddWorkspace: {
        title: 'nuevo espacio de trabajo',
        workspaceName: 'Nombre del espacio de trabajo',
        workspaceNamePlaceholder: 'Nombre del espacio de trabajo...',
        cancel: 'Cancelar',
        save: 'Guardar'
      },
      modalDeleteWorkspace: {
        title: 'Eliminar espacio de trabajo',
        allForms: 'Se eliminarán todos los formularios de este espacio de trabajo. Escribe ',
        toContinue: ' para continuar.',
        workspaceName: 'Nombre del espacio de trabajo',
        workspaceNamePlaceholder: 'Nombre del espacio de trabajo...',
        validationMessages: 'El nombre del espacio de trabajo no coincide.',
        cancel: 'Cancelar',
        deleteTheWorkspace: 'Eliminar el espacio de trabajo y todos sus formularios'
      },
      modalRenameWorkspace: {
        title: 'Rename workspace',
        workspaceName: 'Workspace name',
        placeHolderNewWorkspaceName: 'New workspace name...',
        cancel: 'Cancel',
        rename: 'Rename'
      },
      modalChangeSubdomain: {
        title: 'Cambiar subdominio',
        subdomain: 'Subdominio',
        placeHolderSubdomain: 'Ej. miempresa (texto antes de .yayforms.link)',
        help: 'El subdominio debe ser único y solo puede contener letras o números. Ej. <strong>miempresa</strong> en <strong>miempresa</strong>.yayforms.link',
        cancel: 'Cancelar',
        change: 'Cambiar'
      }
    }
  },
  lib: {
    utils: {
      field: {
        multipleChoice: 'Opción múltiple',
        pictureChoice: 'Elección de imágene',
        opinionScale: 'Escala de opinión',
        yesNo: 'Sí/No',
        nps: 'Net Promoter Score®',
        dropdown: 'Desplegable',
        rating: 'Clasificación',
        email: 'Correo electrónico',
        phone: 'Teléfono',
        date: 'Fecha',
        website: 'Sitio web',
        shortText: 'Texto corto',
        longText: 'Texto largo',
        number: 'Número',
        statement: 'Declaración',
        fileUpload: 'Subir archivo',
        welcomeScreen: 'Pantalla de bienvenida',
        endingScreen: 'Pantalla final',
        calendly: 'Calendly',
        choices: 'opciones',
        contactInfo: 'Datos de contacto',
        textNumber: 'Texto y numero',
        formStructure: 'Estructura del formulario',
        file: 'Archivo',
        integrations: 'Integraciones'
      },
      logic: {
        jumpTo: 'Ir a',
        add: 'Agregar',
        subtract: 'Sustraer',
        multiply: 'Multiplicar',
        divide: 'Dividir',
        by: 'por',
        from: 'de',
        to: 'a',
        is: 'es',
        isNot: 'no es',
        isGreater: 'es mayor que',
        isLess: 'es menos que',
        isGreaterOrEqual: 'es mayor o igual a',
        isLessOrEqual: 'es menor o igual que',
        contains: 'contiene',
        doesntContain: 'no contiene',
        startsWith: 'comienza con',
        endsWith: 'termina con',
        isEmpty: 'Está vacío',
        isNotEmpty: 'No esta vacío',
        isIn: 'es en',
        isNotIn: 'no está dentro',
        isBetween: 'está entre',
        isNotBetween: 'no está entre',
        isToday: 'es hoy',
        isYesterday: 'es ayer',
        isTomorrow: 'Es mañana',
        isAfter: 'es despues',
        isBefore: 'es antes'
      },
      theme: {
        fields: 'Campos',
        answers: 'Respuestas',
        buttons: 'Botones',
        buttonText: 'Texto del botón',
        Background: 'Fondo',
        Font: 'Fuente'
      },
      i18n: {
        en: 'Inglés',
        es: 'Español',
        pt: 'Portugués (Brasil)'
      }
    }
  },
  AG_GRID_LOCALE: {
    selectAll: '(Seleccionar todo)',
    selectAllSearchResults: '(Seleccionar todos los resultados de búsqueda)',
    searchOoo: 'Buscar...',
    blanks: '(espacios en blanco)',
    noMatches: 'No hay coincidencias',
    filterOoo: 'Filtrar...',
    equals: 'igual',
    notEqual: 'No es igual',
    blank: 'Blanco',
    notBlank: 'no en blanco',
    empty: 'Elige uno',
    lessThan: 'Menos que',
    greaterThan: 'Mas grande que',
    lessThanOrEqual: 'Menor o igual',
    greaterThanOrEqual: 'Mayor que o igual',
    inRange: 'En el rango',
    inRangeStart: 'de',
    inRangeEnd: 'a',
    contains: 'Contains',
    notContains: 'no contiene',
    startsWith: 'Comienza con',
    endsWith: 'Termina con',
    dateFormatOoo: 'yyyy-mm-dd',
    andCondition: 'Y',
    orCondition: 'O',
    applyFilter: 'Aplicar',
    resetFilter: 'Reiniciar',
    clearFilter: 'Claro',
    cancelFilter: 'Cancelar',
    textFilter: 'Filtro de texto',
    numberFilter: 'Filtro numérico',
    dateFilter: 'Filtro de fechas',
    setFilter: 'Configurar filtro',
    groupFilterSelect: 'Seleccionar campo:',
    columns: 'Columnas',
    filters: 'Filtros',
    pivotMode: 'Modo de pivote',
    groups: 'Grupos de filas',
    rowGroupColumnsEmptyMessage: 'Arrastre aquí para establecer grupos de filas',
    values: 'Valores',
    valueColumnsEmptyMessage: 'Arrastre aquí para agregar',
    pivots: 'Etiquetas de columna',
    pivotColumnsEmptyMessage: 'Arrastre aquí para configurar las etiquetas de columna',
    group: 'Grupo',
    rowDragRow: 'fila',
    rowDragRows: 'filas',
    loadingOoo: 'Cargando...',
    loadingError: 'ERRAR',
    noRowsToShow: 'No hay filas para mostrar',
    enabled: 'Activado',
    pinColumn: 'Fijar columna',
    pinLeft: 'Fijar a la izquierda',
    pinRight: 'Fijar a la derecha',
    noPin: 'No fijar',
    valueAggregation: 'Agregación de valor',
    noAggregation: 'Ninguno',
    autosizeThiscolumn: 'Tamaño automático de esta columna',
    autosizeAllColumns: 'Tamaño automático de todas las columnas',
    groupBy: 'Agrupar por',
    ungroupBy: 'Desagrupar por',
    resetColumns: 'Restablecer columnas',
    expandAll: 'Expandir todos los grupos de filas',
    collapseAll: 'Cerrar todos los grupos de filas',
    copy: 'Copiar',
    ctrlC: 'Ctrl+C',
    ctrlX: 'Ctrl+X',
    copyWithHeaders: 'Copiar con encabezados',
    copyWithGroupHeaders: 'Copiar con encabezados de grupo',
    cut: 'Cortar',
    paste: 'Pegar',
    ctrlV: 'Ctrl+V',
    export: 'Exportar',
    csvExport: 'Exportación CSV',
    excelExport: 'Exportación de Excel',
    sum: 'Suma',
    first: 'Primero',
    last: 'Último',
    min: 'Min',
    max: 'Max',
    none: 'Ninguno',
    count: 'Contar',
    avg: 'Promedio',
    filteredRows: 'Filtrado',
    selectedRows: 'Seleccionado',
    totalRows: 'Filas totales',
    totalAndFilteredRows: 'filas',
    more: 'Más',
    to: 'a',
    of: 'de',
    page: 'Página',
    pageLastRowUnknown: '?',
    nextPage: 'Siguiente página',
    lastPage: 'Última página',
    firstPage: 'Primera página',
    previousPage: 'Página anterior',
    pivotColumnGroupTotals: 'Total',
    pivotChartAndPivotMode: 'Gráfico dinámico y modo de pivote',
    pivotChart: 'Gráfico dinámico',
    chartRange: 'Rango de gráfico',
    columnChart: 'Columna',
    groupedColumn: 'Agrupados',
    stackedColumn: 'Apilado',
    normalizedColumn: '100% apilado',
    barChart: 'Barra',
    groupedBar: 'Agrupados',
    stackedBar: 'Apilado',
    normalizedBar: '100% apilado',
    pieChart: 'Tarta',
    pie: 'Tarta',
    doughnut: 'Rosquilla',
    line: 'Línea',
    xyChart: 'X Y (Dispersión)',
    scatter: 'Dispersión',
    bubble: 'Burbuja',
    areaChart: 'Área',
    area: 'Área',
    stackedArea: 'Apilado',
    normalizedArea: '100% Apilado',
    histogramChart: 'Histograma',
    histogramFrequency: 'Frecuencia',
    combinationChart: 'Combinación',
    columnLineCombo: 'Columna y Línea',
    AreaColumnCombo: 'Área y Columna',
    ariaChecked: 'comprobado',
    ariaColumn: 'Columna',
    ariaColumnGroup: 'Grupo de columnas',
    ariaColumnList: 'Lista de columnas',
    ariaColumnSelectAll: 'Toggle Seleccionar todas las columnas',
    ariaDateFilterInput: 'Entrada de filtro de fecha',
    ariaDefaultListName: 'Lista',
    ariaFilterColumnsInput: 'Entrada de columnas de filtro',
    ariaFilterFromValue: 'Filtrar por valor',
    ariaFilterInput: 'Entrada de filtro',
    ariaFilterList: 'Lista de filtros',
    ariaFilterToValue: 'Filtrar por valor',
    ariaFilterValue: 'Filtrar valor',
    ariaFilterMenuOpen: 'Abrir menú de filtro',
    ariaFilteringOperator: 'Operador de filtrado',
    ariaHidden: 'oculto',
    ariaIndeterminate: 'indeterminado',
    ariaInputEditor: 'Editor de entrada',
    ariaMenuColumn: 'Presiona CTRL ENTER para abrir el menú de columna',
    ariaRowDeselect: 'Presiona ESPACIO para deseleccionar esta fila',
    ariaRowSelectAll: 'Presiona ESPACIO para alternar la selección de todas las filas',
    ariaRowToggleSelection: 'Presiona ESPACIO para alternar la selección de la fila',
    ariaRowSelect: 'Presiona ESPACIO para seleccionar esta fila',
    ariaSearch: 'Buscar',
    ariaSortableColumn: 'Presione ENTER para ordenar',
    ariaToggleVisibility: 'Presiona ESPACIO para alternar la visibilidad',
    ariaToggleCellValue: 'Presione ESPACIO para alternar el valor de la celda',
    ariaUnchecked: 'desenfrenado',
    ariaVisible: 'visible',
    ariaSearchFilterValues: 'Buscar valores de filtro',
    ariaRowGroupDropZonePanelLabel: 'Grupos de filas',
    ariaValuesDropZonePanelLabel: 'Valores',
    ariaPivotDropZonePanelLabel: 'Etiquetas de columna',
    ariaDropZoneColumnComponentDescription: 'Presiona ENTER para eliminar.',
    ariaDropZoneColumnValueItemDescription: 'Presiona ENTER para cambiar el tipo de agregación.',
    ariaDropZoneColumnGroupItemDescription: 'Presiona ENTER para ordenar.',
    ariaDropZoneColumnComponentAggFuncSeperator: ' de ',
    ariaDropZoneColumnComponentSortAscending: 'ascendente',
    ariaDropZoneColumnComponentSortDescending: 'descendente',
    ariaLabelColumnMenu: 'Menú de columnas',
    ariaLabelCellEditor: 'Editor celular',
    ariaLabelDialog: 'Diálogo',
    ariaLabelSelectField: 'Seleccionar campo',
    ariaLabelTooltip: 'Información sobre herramientas',
    ariaLabelContextMenu: 'Menú de contexto',
    ariaLabelSubMenu: 'Submenú',
    ariaLabelAggregationFunction: 'Función de agregación',
    thousandSeparator: ',',
    decimalSeparator: '.',
    true: 'Verdadero',
    false: 'Falso',
    invalidDate: 'Fecha invalida',
    invalidNumber: 'Número invalido',
    january: 'Enero',
    february: 'Febrero',
    march: 'Marzo',
    april: 'Abril',
    may: 'Puede',
    june: 'Junio',
    july: 'Julio',
    august: 'Agosto',
    september: 'September',
    october: 'Octubre',
    november: 'Noviembre',
    december: 'Diciembre'
  }
}
