import { mapState } from 'vuex'

const plans = {
  free: 'free',
  starter: 'starter',
  plus: 'plus',
  professional: 'professional',
  enterprise: 'enterprise',
  all: ['free', 'starter', 'plus', 'professional', 'enterprise'],
  paid: ['starter', 'plus', 'professional', 'enterprise']
}

const constraints = {
  limit: 0,
  availability: 1
}

export const resources = {
  hideCredits: 'hideCredits',
  customEndingScreen: 'customEndingScreen',
  customThemes: 'customThemes',
  createForm: 'forms',
  createField: 'fields',
  inviteUser: 'users',
  createWorkspace: 'workspaces',
  receiveResponses: 'responses',
  textAnalysis: 'textAnalysis',
  keywordsExtraction: 'keywordsExtraction',
  customSubdomain: 'customSubdomain',
  haveAddOns: 'haveAddOns'
}

const features = [
  { resource: resources.hideCredits, name: 'Hide Powered by Yay! Forms', action: 'hide powered by Yay! Forms', plans: [plans.plus, plans.professional, plans.enterprise], constraint: constraints.availability },
  { resource: resources.customEndingScreen, name: 'Custom Ending Page', action: 'create custom ending screen', plans: plans.paid, constraint: constraints.availability },
  { resource: resources.customThemes, name: 'Custom Themes', action: 'create custom themes', plans: plans.paid, constraint: constraints.availability },
  { resource: resources.createForm, name: 'Create form', action: 'create new forms', plans: plans.all, constraint: constraints.limit },
  { resource: resources.createField, name: 'Create field', action: 'create new field', plans: plans.all, constraint: constraints.limit },
  { resource: resources.inviteUser, name: 'Invite user', action: 'invite user', plans: plans.all, constraint: constraints.limit },
  { resource: resources.createWorkspace, name: 'Create workspace', action: 'create a new workspace', plans: plans.all, constraint: constraints.limit },
  { resource: resources.receiveResponses, name: 'Receive responses', action: 'receive new responses', plans: plans.all, constraint: constraints.limit },
  { resource: resources.textAnalysis, name: 'Text Analysis', action: 'automatically analyze sentiment and emotion of an answer', plans: plans.paid, constraint: constraints.limit },
  { resource: resources.keywordsExtraction, name: 'Keyword Extraction', action: 'automatically extract keywords from answers', plans: plans.paid, constraint: constraints.limit },
  { resource: resources.customSubdomain, name: 'Custom Subdomain', action: 'use a custom subdomain', plans: [plans.plus, plans.professional, plans.enterprise], constraint: constraints.availability },
  { resource: resources.haveAddOns, name: 'Have Add-ons', action: 'have add-ons', plans: plans.paid, constraint: constraints.availability }
]

export default {
  computed: {
    ...mapState({
      planFeatures: state => state.account.planFeatures,
      subscription: state => state.account.subscription
    })
  },

  methods: {
    canUseFeature (resource, showMessage = false) {
      if (!resources[resource]) {
        throw new Error(`Invalid resource: ${resource}`)
      }

      const feature = features.find(feature => feature.resource === resource)

      if (!feature) { return false }

      let allowed = feature.plans.includes(this.subscription.currentPlan)
      let message = `Your current plan (${this.subscription.currentPlan}) doesn't allow you to ${feature.action}. Please upgrade your plan to proceed.`

      if (allowed && feature.constraint === constraints.limit) {
        const planFeature = this.planFeatures[resource]
        allowed = planFeature.balance === null || planFeature.balance > 0
        message = `Your current plan (${this.subscription.currentPlan}) has a limit of ${planFeature.limit} ${resource}. Please upgrade your plan to ${feature.action}.`
      }

      if (showMessage && !allowed) {
        this.$msgbox.confirmUpgrade(this, feature.name, message)
      }

      return allowed
    }
  }
}
